import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import JfgTitle from "../../components/titles/JfgTitle";

const CausesPage = () => {
    return (
        <Layout pageTitle="JCI INdia Foundation | JFG">
            <NavOne />
            <PageHeader title="JFG" />
            <JfgTitle />
            <Footer />
        </Layout>
    );
};

export default CausesPage;
